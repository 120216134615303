<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
            <el-tab-pane label="概览" name="first">
                <el-form ref="form" label-width="80px" class="search" :model="queryForm">
                    <el-select v-model="selectLib" placeholder="请选择分馆" @change="showTollibRegion" style="width: 50%;">
                        <el-option v-for="item in libRegion" :key="item.branchName" :label="item.branchName"
                            :value="item.branchName">
                        </el-option>
                    </el-select>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="showTolLibsearch">查询</el-button>
                    </el-form-item>
                </el-form>

                <div style="font-weight: bold;">图书馆概况</div>
                <div class="LibStatus">
                    <div>
                        <div class="number">{{ labCondition.inLibraryBookNum }}</div>
                        <div class="txt">在馆图书/册</div>
                    </div>
                    <div class="line"></div>
                    <div>
                        <div class="number">{{ labCondition.inLendBookNum }}</div>
                        <div class="txt">在借图书/册</div>
                    </div>
                    <div class="line"></div>

                    <div>
                        <div class="number">{{ labCondition.lendBookNum }}</div>
                        <div class="txt">借阅总数/册</div>
                    </div>
                    <div class="line"></div>

                    <div>
                        <div class="number">{{ labCondition.inLibraryReaderNum }}</div>
                        <div class="txt">馆内读者/位</div>
                    </div>
                    <div class="line"></div>

                    <div>
                        <div class="number">{{ labCondition.reserveLibraryBookNum }}</div>
                        <div class="txt">馆藏图书/册</div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="借阅数据" name="second" class="second" :stretch="true">
                <div style="font-weight: bold;">图书借还趋势</div>
                <el-form :inline="true" :model="queryLendForm">
                    <el-select v-model="queryLendForm.region" placeholder="请选择分馆" style="width: 30%;"
                        @change="showLendlibRegion">
                        <el-option v-for="item in libRegion" :key="item.branchName" :label="item.branchName"
                            :value="item.id">
                        </el-option>
                    </el-select>

                    <el-select v-model="queryLendForm.relativeDay" placeholder="时间" style="width: 13%;margin: 5px 10px;"
                        @change="showLendrelativeDayList">
                        <el-option v-for="item in relativeDayList" :key="item.choose" :label="item.choose"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <span v-if="queryLendForm.relativeDay != 0" style=" width: 30%; margin-left: 10px;">
                        <span style="color: gray; font-size: 15px;line-height: 2.45; width: 200%;">
                            {{ dateArray[(dateArray.length) - 1] }} ~ {{ dateArray[0] }}
                        </span>
                    </span>
                    <el-date-picker v-if="queryLendForm.relativeDay == 0" v-model="queryLendForm.Datevalue" type="daterange"
                        @change="LendformatDate" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        style="margin-right: 10px;width: 25%;">
                    </el-date-picker>

                    <el-select v-model="queryLendForm.obyeTime" placeholder="请选择" @change="showLendobyeTimeList"
                        style="width: 15%;margin-top: 10px;">
                        <el-option v-for="item in obyeTimeList" :key="item.choose1" :label="item.choose1"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-form-item style="margin: 10px ;">
                        <el-button type="primary" size="small" @click="LendSearch">查询</el-button>
                    </el-form-item>
                </el-form>

                <div class="second-right" v-if="queryLendForm.relativeDay != '自定义'">
                    <div class="number">{{ LendAverage }}</div>
                    <div class="txt">人均借阅册数</div>
                </div>

                <div id="main1"></div>
            </el-tab-pane>
            <el-tab-pane label="图书数据" name="third">
                <div style="font-weight: bold;">图书排行榜</div>
                <el-form ref="form" :model="queryBookForm" label-width="80px" class="search">
                    <el-select v-model="queryBookForm.region" placeholder="请选择分馆" style="width: 50%;"
                        @change="showBooklibRegion">
                        <el-option v-for="item in libRegion" :key="item.branchName" :label="item.branchName"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="queryBookForm.relativeDay" placeholder="时间" style="width: 30%;margin-left: 10px;"
                        @change="showBookrelativeDayList">
                        <el-option v-for="item in relativeDayList" :key="item.choose" :label="item.choose"
                            :value="item.value">
                        </el-option>
                    </el-select>

                    <div v-if="queryBookForm.relativeDay != 0" style=" width: 30%; margin-left: 10px;">
                        <div style="color: gray; font-size: 13px;line-height: 2.45;">
                            {{ dateArray[(dateArray.length) - 1] }}~{{ dateArray[0] }}
                        </div>
                    </div>
                    <el-date-picker v-if="queryBookForm.relativeDay == '0'" v-model="queryBookForm.Datevalue"
                        style="margin-left: 10px;width: 100%;" @change="BookformatDate" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                    <!-- 借阅总数 -->
                    <el-select v-model="queryBookForm.sendParams" placeholder="请选择" style="margin-left: 10px;"
                        @change="showBooksendParamsList">
                        <el-option v-for="item in sendParamsList" :key="item.choose2" :label="item.choose2"
                            :value="item.type">
                        </el-option>
                    </el-select>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="BookSearch">查询</el-button>
                    </el-form-item>
                </el-form>

                <el-table :data="BookList" stripe style="width: 100%">
                    <el-table-column prop="order" label="序号" width="180">
                    </el-table-column>
                    <el-table-column prop="lendCount" label="借阅总数" width="180">
                    </el-table-column>
                    <el-table-column prop="bookName" label="图书名称">
                        <template slot-scope="scope">
                            <el-link href="#" type="primary">{{ scope.row.bookName }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isbn" label="IBSN码">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="读者数据" name="fourth">
                <div style="font-weight: bold;">读者数量</div>

                <el-form :model="queryReaderForm" style="display: flex;flex-direction: column;">
                    <el-select v-model="queryReaderForm.region" placeholder="请选择分馆" style="width: 50%;"
                        @change="showReaderlibRegion">
                        <el-option v-for="item in libRegion" :key="item.branchName" :label="item.branchName"
                            :value="item.id">
                        </el-option>
                    </el-select>

                    <el-select v-model="queryReaderForm.relativeDay" placeholder="时间" style="width: 30%;margin: 5px 0;"
                        @change="showReaderrelativeDayList">
                        <el-option v-for="item in relativeDayList" :key="item.choose" :label="item.choose"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <div v-if="queryReaderForm.relativeDay != 0" style=" width: 30%; margin-left: 10px;">
                        <div style="color: gray; font-size: 14px;line-height: 2.45; width: 200%;">
                            {{ dateArray[(dateArray.length) - 1] }} ~ {{ dateArray[0] }}
                        </div>
                    </div>
                    <el-date-picker v-if="queryReaderForm.relativeDay == 0" v-model="queryReaderForm.Datevalue"
                        type="daterange" @change="showReaderformatDate" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>

                    <el-select v-model="queryReaderForm.obyeTime" placeholder="请选择" @change="showReaderobyeTimeList"
                        style="width: 30%;margin-top: 10px;">
                        <el-option v-for="item in obyeTimeList" :key="item.choose1" :label="item.choose1"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="ReaderSearch">查询</el-button>
                    </el-form-item>
                </el-form>

                <div id="main2"></div>

                <div v-if="active == '3'" class="bottom">
                    <div class="right">
                        <div style="display: flex;justify-content: space-between;">
                            <div style="font-weight: bold;">读者画像</div>
                            <div style="color: rgb(133, 133, 133);">数据更新至昨天：{{ YesDate }}</div>
                        </div> 
                        <el-form :model="queryReaderFigureForm" :inline="true" style="margin-top: 20px;">
                            <el-select v-model="queryReaderFigureForm.region" placeholder="请选择分馆" style="width: 50%;"
                                @change="showReaderFigureRegion">
                                <el-option v-for="item in libRegion" :key="item.branchName" :label="item.branchName"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="ReaderFigureSearch"
                                    style="margin-left: 15px;">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="left">
                        <div style="font-weight: bold;">读者排行榜</div>
   
                        <el-form :model="queryReaderRankForm" style="display: flex;flex-direction: column;">
                            <el-select v-model="queryReaderRankForm.region" placeholder="请选择分馆" style="width: 50%;"
                                @change="showReaderRanklibRegion">
                                <el-option v-for="item in libRegion" :key="item.branchName" :label="item.branchName"
                                    :value="item.id">
                                </el-option>
                            </el-select>

                            <el-select v-model="queryReaderRankForm.relativeDay" placeholder="时间"
                                style="width: 30%;margin: 5px 0;" @change="showReaderRankrelativeDayList">
                                <el-option v-for="item in relativeDayList" :key="item.choose" :label="item.choose"
                                    :value="item.value">
                                </el-option>
                            </el-select>

                            <div v-if="queryReaderRankForm.relativeDay != 0" style=" width: 30%; margin-left: 10px;">
                                <div style="color: gray; font-size: 14px;line-height: 2.45; width: 200%;">
                                    {{ dateArray[(dateArray.length) - 1] }} ~ {{ dateArray[0] }}
                                </div>
                            </div>
                            <el-date-picker v-if="queryReaderRankForm.relativeDay == 0" v-model="queryReaderRankForm.value"
                                type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="ReaderRankSearch">查询</el-button>
                            </el-form-item>
                        </el-form>

                        <el-table :data="ReaderRankList" style="width: 100%"
                            :default-sort="{ prop: 'date', order: 'descending' }">
                            <el-table-column prop="order" label="序号" width="135">
                            </el-table-column>
                            <el-table-column prop="lendNum" label="借阅总数(册)" width="100">
                            </el-table-column>
                            <el-table-column prop="username" label="姓名" width="100">
                                <template slot-scope="scope">
                                    <el-link href="#" type="primary">{{ scope.row.username }}</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>

                <div class="circle">
                    <div id="main3"></div>
                    <div id="main4"></div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="图书馆数据" name="fifth" class="five">
                <div class="top">
                    <div style="color: rgb(133, 133, 133);font-size: 14px;">
                        数据截止至：
                        <el-date-picker v-model="delineDate" type="date" placeholder="选择日期" @change="getData">
                        </el-date-picker>
                    </div>
                    <!-- <el-button size="small" icon="el-icon-tickets">导出</el-button> -->
                </div>

                <el-table :data="LabData" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
                    <el-table-column prop="branchName" label="分馆名称" width="220">
                    </el-table-column>
                    <el-table-column prop="inLendBookNum" label="在借图书/册" sortable>
                    </el-table-column>
                    <el-table-column prop="inLibraryBookNum" label="在馆图书/册" sortable>
                    </el-table-column>
                    <el-table-column prop="reserveLibraryBookNum" label="馆藏图书/册" sortable>
                    </el-table-column>
                    <el-table-column prop="historyBookNum" label="历史图书总数/册" sortable>
                    </el-table-column>
                    <el-table-column prop="lendBookNum" label="借阅总数/册" sortable>
                    </el-table-column>
                    <el-table-column prop="inLibraryReaderNum" label="馆内读者/位" sortable>
                    </el-table-column>
                    <el-table-column prop="historyReaderNum" label="历史读者总数/位" sortable>
                    </el-table-column>
                    <el-table-column prop="historyBookNum" label="当日新增图书/册" sortable>
                    </el-table-column>
                    <el-table-column prop="dayAddReaderNum" label="当日新增读者/位" sortable>
                    </el-table-column>
                    <el-table-column prop="dayLendBookNum" label="当日借出图书/册" sortable>
                    </el-table-column>
                    <el-table-column prop="dayReturnBookNum" label="当日归还图书/册" sortable>
                    </el-table-column>
                    <el-table-column prop="historyBookNum" label="当日借出读者/位" sortable>
                    </el-table-column>
                    <el-table-column prop="dayReturnReaderNum" label="当日还书读者/位" sortable>
                    </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" :current-page="currentPage4" :page-sizes="[2, 5, 10, 20]"
                    :page-size="this.pageSize" layout="total, sizes, prev, pager, next, jumper"
                    :total="this.LabData.length">
                </el-pagination>
            </el-tab-pane>
        </el-tabs>
        <div v-if="active == '0'" class="TodayData">
            <div style="font-weight: bold;">今日数据</div>
            <div class="LibStatus1">
                <div>
                    <div class="number">{{ labCondition.dayAddBookNum }}</div>
                    <div class="txt">今日新增图书/册</div>
                </div>
                <div class="line"></div>
                <div>
                    <div class="number">{{ labCondition.dayAddReaderNum }}</div>
                    <div class="txt">今日新增读者/位</div>
                </div>
                <div class="line"></div>
                <div>
                    <div class="number">{{ labCondition.dayLendBookNum }}</div>
                    <div class="txt">今日借出/册</div>
                </div>
                <div class="line"></div>
                <div>
                    <div class="number">{{ labCondition.dayReturnBookNum }}</div>
                    <div class="txt">今日归还/位</div>
                </div>
                <div class="line"></div>
                <div>
                    <div class="number">{{ labCondition.dayLendReaderNum }}</div>
                    <div class="txt">今日借书读者/位</div>
                </div>
                <div class="line"></div>
                <div>
                    <div class="number">{{ labCondition.dayReturnReaderNum }}</div>
                    <div class="txt">今日还书读者/位</div>
                </div>
            </div>
        </div>
        <div v-if="active == '0'" class="OtherData">
            <div style="font-weight: bold;">其他数据</div>
            <div class="LibStatus">
                <div>
                    <div class="number">{{ labCondition.historyBookNum }}</div>
                    <div class="txt">历史图书总数/册</div>
                </div>
                <div class="line"></div>
                <div>
                    <div class="number">{{ labCondition.historyReaderNum }}</div>
                    <div class="txt">历史读者总数/册</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { listLibrary, libraryData } from '@/api/library'
import { countLendRecord, countLendRecordRank, countLendRecordReader, countLendRecordReaderRank } from '@/api/lendRecord'
import { formatDate } from '@/utils/formatDate'
import { listBook } from '@/api/book'
import { listReader, readerFigure } from '@/api/reader'
import * as echarts from 'echarts'

export default {
    data() {
        return {
            YesDate: "",

            // 限定显示
            active: "0",
            // 默认第一次选中tabs
            activeName: 'first',
            queryForm: {
                // 分馆
                region: '所有分馆',
                // 近几天
                relativeDay: '近7天',
                // 按天、月、年
                obyeTime: "按天",
                // 借阅人数，总数参数选择
                sendParams: "借阅总数",
                // 读者
                type: 'cardNo',
                keyWord: undefined,
            },
            // 借阅数据的表单
            queryLendForm: {
                // 分馆
                region: '所有分馆',
                // 近几天
                relativeDay: '近7天',
                // 按天、月、年
                obyeTime: "按天",
                // 借阅人数，总数参数选择
                sendParams: "借阅总数",
                // 读者,
                Datevalue: 0,
                value0: 0,
                value1: 0,
                type: 'cardNo',
                keyWord: undefined,
            },
            // 图书数据的表单
            queryBookForm: {
                // 分馆
                region: '所有分馆',
                // 近几天
                relativeDay: '近7天',
                // 按天、月、年
                obyeTime: "按天",
                // 借阅人数，总数参数选择
                sendParams: "借阅总数",
                Datevalue: 0,
                value0: 0,
                value1: 0,
                // 读者
                type: 'cardNo',
                keyWord: undefined,
            },
            // 读者数据的表单
            queryReaderForm: {
                // 分馆
                region: '所有分馆',
                // 近几天
                relativeDay: '近7天',
                // 按天、月、年
                obyeTime: "按天",
                // 借阅人数，总数参数选择
                sendParams: "借阅总数",
                Datevalue: 0,
                value0: 0,
                value1: 0,
                // 读者
                type: 'cardNo',
                keyWord: undefined,
            },
            // 读者排行榜
            queryReaderRankForm: {
                // 分馆
                region: '所有分馆',
                // 近几天
                relativeDay: '近7天',
                // 按天、月、年
                obyeTime: "按天",
                // 借阅人数，总数参数选择
                sendParams: "借阅总数",
                Datevalue: 0,
                value0: 0,
                value1: 0,
                // 读者
                type: 'cardNo',
                keyWord: undefined,
            },
            // 读者画像
            queryReaderFigureForm: {
                // 分馆
                region: '所有分馆',
            },
            // 时间范围
            relativeDayList: [
                { choose: "近7天", value: 7 },
                { choose: "近30天", value: 30 },
                { choose: "近90天", value: 90 },
                { choose: "近180天", value: 180 },
                { choose: "自定义", value: 0 }
            ],
            // 天数
            obyeTimeList: [
                { choose1: "按天", value: 0 },
                { choose1: "按周", value: 1 },
                { choose1: "按月", value: 2 }
            ],
            // 借阅人数，总数参数选择
            sendParamsList: [
                { choose2: "借阅总数", type: 0 },
                { choose2: "借阅人数", type: 1 }
            ],
            // 图书馆分馆信息
            libRegion: [],
            // 概括中选中的图书馆名,
            selectLib: "所有分馆",
            libAll: { "branchName": "所有分馆", "id": "0" },
            // 分页数据
            page: {
                page: 1, // 当前页
                size: 10, // 每页显示数
                total: undefined // 总计
            },
            // 获得前一天日子的0点整时间
            yesterdayTime: "",
            // 截止时间
            delineDate: new Date(),
            delineDateBak: "",
            // 今天时间
            nowDate: "",
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀'
            }],
            // 分页
            currentPage1: 5,
            currentPage2: 5,
            currentPage3: 5,
            currentPage4: 4,
            // 概括中的数据
            labCondition: {
                dayAddBookNum: 0,
                dayAddReaderNum: 0,
                dayLendBookNum: 0,
                dayLendReaderNum: 0,
                dayReturnBookNum: 0,
                dayReturnReaderNum: 0,
                historyBookNum: 0,
                historyReaderNum: 0,
                inLendBookNum: 0,
                inLibraryBookNum: 0,
                inLibraryReaderNum: 0,
                lendBookNum: 0,
                reserveLibraryBookNum: 0,
            },
            // 备份重置，防止无限加
            labConditionBak: {
                dayAddBookNum: 0,
                dayAddReaderNum: 0,
                dayLendBookNum: 0,
                dayLendReaderNum: 0,
                dayReturnBookNum: 0,
                dayReturnReaderNum: 0,
                historyBookNum: 0,
                historyReaderNum: 0,
                inLendBookNum: 0,
                inLibraryBookNum: 0,
                inLibraryReaderNum: 0,
                lendBookNum: 0,
                reserveLibraryBookNum: 0,
            },
            // 概况中的图书信息
            labList: [],
            // 展示的时间
            dateArray: [],

            // ===========借阅数据============
            selectLendLibId: "",
            LendType: "",
            LendLib: [],
            LendAverage: 0,
            eLegendData1: [],
            exAxis1: [],
            eseries1: [
                { name: '借出册数', type: 'line', stack: 'x', data: 1 },
                { name: '归还册数', type: 'line', stack: 'x', data: 1 }
            ],

            // ============图书数据=============
            selectBookLibId: "",
            Booktime: "",
            BookType: 0,
            BookList: [],

            // ============读者数据============
            selectReaderLibId: "",
            ReaderType: "",
            Readertime: "",
            ReaderList: [],
            eLegendData2: [],
            exAxis2: [],
            eseries2: [
                { name: '活跃读者', type: 'line', stack: 'x', data: 1 },
                { name: '馆内读者', type: 'line', stack: 'x', data: 1 }
            ],

            // ============读者排行榜数据============
            selectReaderRankLibId: "",
            ReaderRankType: "",
            ReaderRankList: [],

            // =============读者画像==========
            selectReaderFigureLibId: "",
            eLegendData3: [],
            exAxis3: [],
            eseries3: [
                { value: 1048, name: '男' },
                { value: 735, name: '女' },
                { value: 1048, name: '其他' },
            ],

            // =============图书馆数据==========
            LabData: [],
            pageSize: 10,


            value: "",
            Datevalue: "",


        };
    },
    mounted() {
        // ====================借阅数据echarts===============
        this.selectReaderLibId = 1;
        const queryLendParams = { beginDate: this.dateArray[(this.dateArray.length) - 1], endDate: this.dateArray[0], type: this.ReaderType ? this.ReaderType : 0, bid: this.selectReaderLibId };
        countLendRecord(queryLendParams).then(({ data }) => {
            this.LendAverage = data.average == 'NaN' ? 0 : data.average;
            this.exAxis1 = data.lendDateList;
            this.eseries1[0].data = data.lendList;
            this.eseries1[1].data = data.returnList;
            this.myChart1.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                title: {
                    text: '统计'
                },
                legend: {
                    data: ['借出册数', '归还册数',]
                },
                xAxis: {
                    data: this.exAxis1
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {},
                series: [
                    {
                        name: '借出册数',
                        type: 'line',
                        data: this.eseries1[0].data,
                        type: 'line',
                        stack: 'x'
                    },
                    {
                        name: '归还册数',
                        type: 'line',
                        data: this.eseries1[1].data,
                        type: 'line',
                        stack: 'x'
                    }
                ]
            })
            window.addEventListener('resize', () => {
                this.myChart1.resize()
            })
        })

        // ===================读者数据echarts=================
        this.selectReaderLibId = 1;
        const queryReaderParams = { beginDate: this.dateArray[(this.dateArray.length) - 1], endDate: this.dateArray[0], type: this.ReaderType ? this.ReaderType : 0, bid: this.selectReaderLibId };
        countLendRecordReader(queryReaderParams).then(({ data }) => {
            this.eLegendData2 = data.inLibraryDateList;
            this.eseries2[0].data = data.activeReaderList;
            this.eseries2[1].data = data.inLibraryReaderList;
            this.myChart2.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                title: {
                    text: '统计'
                },
                legend: {
                    data: ['活跃读者', '馆内读者',]
                },
                xAxis: {
                    data: this.exAxis2
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {},
                series: [
                    {
                        name: '活跃读者',
                        type: 'line',
                        data: this.eseries2[0].data,
                        type: 'line',
                        stack: 'x'
                    },
                    {
                        name: '馆内读者',
                        type: 'line',
                        data: this.eseries2[1].data,
                        type: 'line',
                        stack: 'x'
                    }
                ]
            })
            window.addEventListener('resize', () => {
                this.myChart2.resize()
            })
        })

        // ===================读者画像echarts==========================
        // 性别
        this.getYesterDay();
        const queryReaderFigureParams = { bid: 1 }
        readerFigure(queryReaderFigureParams).then(({ data }) => {
            this.eseries3[0].value = data.manNum;
            this.eseries3[1].value = data.womanNum;
            this.eseries3[2].value = data.otherNum;
            this.exAxis3 = data.ageList;
            console.log(this.exAxis3);
            console.log(this.exAxis3[0]);
            console.log(this.exAxis3[1]);
            console.log(this.exAxis3[2]);
            console.log(this.exAxis3[3]);
            this.myChart3.setOption({
                title: {
                    text: '性别',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '40%',
                        data: [
                            { value: this.eseries3[0].value, name: '男' },
                            { value: this.eseries3[1].value, name: '女' },
                            { value: this.eseries3[2].value, name: '其他' },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            })
            this.myChart4.setOption({
                title: {
                    text: '年龄',
                    left: '60%'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        center:['65%', '45%'],
                        data: [
                            { value: this.exAxis3[0], name: '0-3' },
                            { value: this.exAxis3[1], name: '4-6' },
                            { value: this.exAxis3[2], name: '7-9' },
                            { value: this.exAxis3[3], name: '10-12' },
                            { value: this.exAxis3[4], name: '13-15' },
                            { value: this.exAxis3[5], name: '16-18' },
                            { value: this.exAxis3[6], name: '18以上' },
                            { value: this.exAxis3[7], name: '为空' },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            })
            window.addEventListener('resize', () => {
                this.myChart3.resize()
            })
            window.addEventListener('resize', () => {
                this.myChart4.resize()
            })
        })

        //年龄


        // 显示图表
        this.myChart1 = echarts.init(document.getElementById('main1'));
        this.myChart2 = echarts.init(document.getElementById('main2'));
        this.myChart3 = echarts.init(document.getElementById('main3'));
        this.myChart4 = echarts.init(document.getElementById('main4'));

        // =================== 图书馆数据 =================
        // this.getData(this.delineDateBak)
    },
    created() {
        this.getYesDate();
        this.getLibList();
        this.showTolLibsearch();
        this.showLendlibRegion();
        this.showBooklibRegion();
        this.showReaderlibRegion();
        this.delineDateBak = formatDate(this.delineDate)
        this.getData(this.delineDateBak, 5)
    },
    methods: {
        // =================== 概览 =================
        // 概览中选择分馆
        showTollibRegion(data) { this.selectLib = data; },
        // 概览中点击查询
        async showTolLibsearch() {
            this.getnowDate()
            const queryParams = { date: this.nowDate };
            // 获得对应的分馆信息
            await libraryData(queryParams).then(({ data }) => {
                if (this.selectLib === "所有分馆") {
                    this.labList = data;
                    console.log(this.labList);
                }
                else {
                    // filter要设置新变量接收才行
                    this.labList = data.filter((item) => { return item.branchName === this.selectLib })
                }
            }).then(() => {
                if (this.selectLib === '所有分馆') {
                    this.labCondition = this.labConditionBak;
                    for (let index = 0; index < this.labList.length; index++) {
                        this.labCondition.dayAddBookNum += this.labList[index].dayAddBookNum;
                        this.labCondition.dayAddReaderNum += this.labList[index].dayAddReaderNum;
                        this.labCondition.dayLendBookNum += this.labList[index].dayLendBookNum;
                        this.labCondition.dayLendReaderNum += this.labList[index].dayLendReaderNum;
                        this.labCondition.dayReturnBookNum += this.labList[index].dayReturnBookNum;
                        this.labCondition.dayReturnReaderNum += this.labList[index].dayReturnReaderNum;
                        this.labCondition.historyBookNum += this.labList[index].historyBookNum;
                        this.labCondition.historyReaderNum += this.labList[index].historyReaderNum;
                        this.labCondition.inLendBookNum += this.labList[index].inLendBookNum;
                        this.labCondition.inLibraryBookNum += this.labList[index].inLibraryBookNum;
                        this.labCondition.inLibraryReaderNum += this.labList[index].inLibraryReaderNum;
                        this.labCondition.lendBookNum += this.labList[index].lendBookNum;
                        this.labCondition.reserveLibraryBookNum += this.labList[index].reserveLibraryBookNum;
                    }
                }
                else {
                    this.labCondition = this.labConditionBak
                    // filter后，数组会改变，需要指定下标
                    this.labCondition.dayAddBookNum = this.labList[0].dayAddBookNum;
                    this.labCondition.dayAddReaderNum = this.labList[0].dayAddReaderNum;
                    this.labCondition.dayLendBookNum = this.labList[0].dayLendBookNum;
                    this.labCondition.dayLendReaderNum = this.labList[0].dayLendReaderNum;
                    this.labCondition.dayReturnBookNum = this.labList[0].dayReturnBookNum;
                    this.labCondition.dayReturnReaderNum = this.labList[0].dayReturnReaderNum;
                    this.labCondition.historyBookNum = this.labList[0].historyBookNum;
                    this.labCondition.historyReaderNum = this.labList[0].historyReaderNum;
                    this.labCondition.inLendBookNum = this.labList[0].inLendBookNum;
                    this.labCondition.inLibraryBookNum = this.labList[0].inLibraryBookNum;
                    this.labCondition.inLibraryReaderNum = this.labList[0].inLibraryReaderNum;
                    this.labCondition.lendBookNum = this.labList[0].lendBookNum;
                    this.labCondition.reserveLibraryBookNum = this.labList[0].reserveLibraryBookNum;
                }
            })
        },
        // 前一天时间 0今天时间 -1昨天时间
        getnowDate(date = 0) {
            if (date == -1) {
                const previousDate = new Date(new Date());
                previousDate.setDate(previousDate.getDate() - 1);
                // 格式化日期
                const year = previousDate.getFullYear();
                const month = String(previousDate.getMonth() + 1).padStart(2, '0');
                const day = String(previousDate.getDate()).padStart(2, '0');
                this.delineDate = `${year}-${month}-${day}`;
            } else {
                const previousDate = new Date(new Date());
                previousDate.setDate(previousDate.getDate());
                // 格式化日期
                const year = previousDate.getFullYear();
                const month = String(previousDate.getMonth() + 1).padStart(2, '0');
                const day = String(previousDate.getDate()).padStart(2, '0');
                this.nowDate = `${year}-${month}-${day}`;
            }
        },

        // =============== 借阅数据 ==================
        // 选择“近7、30、180天” 根据得到的value
        showLendrelativeDayList(value) { this.getYesDate(value);  },
        showLendobyeTimeList(type) { this.LendType = type; console.log(this.LendType); },
        // 自定义时间
        LendformatDate() {
            this.queryLendForm.value0 = formatDate(this.queryLendForm.Datevalue[0])
            this.queryLendForm.value1 = formatDate(this.queryLendForm.Datevalue[1])
        },
        showLendlibRegion(data) {
            this.selectLendLibId = data;
        },
        async LendSearch() {
            let beginDate, endDate;
            if (this.queryLendForm.value0 != 0 && this.queryLendForm.value1 != 0 && this.queryLendForm.relativeDay == '0') {
                beginDate = this.queryLendForm.value0;
                endDate = this.queryLendForm.value1;
            }
            else {
                beginDate = this.dateArray[(this.dateArray.length) - 1]
                endDate = this.dateArray[0]
            }
            const queryParams = { beginDate: beginDate, endDate: endDate, type: this.LendType ? this.LendType : 0, bid: this.selectLendLibId };
            this.LendLib = this.libRegion.filter(item => {
                return item.id == this.selectLendLibId
            })
            await countLendRecord(queryParams).then(({ data }) => {
                console.log(data);
                this.LendAverage = data.average == "NaN" ? 0 :  data.average;
                this.exAxis1 = data.lendDateList;
                this.eseries1[0].data = data.lendList;
                this.eseries1[1].data = data.returnList;
            })
            const options = {
                tooltip: {
                    trigger: 'axis'
                },
                title: {
                    text: '统计'
                },
                legend: {
                    data: ['借出册数', '归还册数',]
                },
                xAxis: {
                    data: this.exAxis1
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {},
                series: [
                    {
                        name: '借出册数',
                        type: 'line',
                        data: this.eseries1[0].data,
                        type: 'line',
                        stack: 'x'
                    },
                    {
                        name: '归还册数',
                        type: 'line',
                        data: this.eseries1[1].data,
                        type: 'line',
                        stack: 'x'
                    }
                ]
            }
            this.myChart1.setOption(options)
        },

        // ================== 图书数据 =============
        showBookrelativeDayList(value) { this.getYesDate(value) },
        showBooksendParamsList(value) { this.BookType = value; console.log(this.BookType); },
        BookformatDate() {
            this.queryBookForm.value0 = formatDate(this.queryBookForm.Datevalue[0])
            this.queryBookForm.value1 = formatDate(this.queryBookForm.Datevalue[1])
        },
        showBooklibRegion(data) {
            this.selectBookLibId = data ? data : 1;
        },
        async BookSearch() {
            let beginDate, endDate;
            if (this.queryBookForm.value0 != 0 && this.queryBookForm.value1 != 0 && this.queryBookForm.relativeDay == '0') {
                beginDate = this.queryBookForm.value0;
                endDate = this.queryBookForm.value1;
            }
            else {
                beginDate = this.dateArray[(this.dateArray.length) - 1]
                endDate = this.dateArray[0]
            }
            console.log(beginDate, endDate, this.BookType, this.selectBookLibId);
            const queryParams = { beginDate: beginDate, endDate: endDate, type: this.BookType ? this.BookType : 0, bid: this.selectBookLibId }
            await countLendRecordRank(queryParams).then(({ data }) => {
                console.log(data);
                this.BookList = data.sort((a, b) => b.lendCount - a.lendCount);
                this.BookList = this.BookList.map((item, index) => {
                    return { ...item, order: index + 1 };
                });
            })
            console.log(this.BookList);
        },

        // ================ 读者数据 ===================
        showReaderrelativeDayList(value) { this.getYesDate(value); },
        showReaderobyeTimeList(type) { this.ReaderType = type; console.log(this.ReaderType); },
        showReaderlibRegion(data) { this.selectReaderLibId = data ? data : 1; },
        showReaderformatDate() {
            this.queryReaderForm.value0 = formatDate(this.queryReaderForm.Datevalue[0])
            this.queryReaderForm.value1 = formatDate(this.queryReaderForm.Datevalue[1])
        },
        async ReaderSearch() {
            let beginDate, endDate;
            if (this.queryReaderForm.value0 != 0 && this.queryReaderForm.value1 != 0 && this.queryReaderForm.relativeDay == '0') {
                beginDate = this.queryReaderForm.value0;
                endDate = this.queryReaderForm.value1;
            }
            else {
                beginDate = this.dateArray[(this.dateArray.length) - 1]
                endDate = this.dateArray[0]
            }
            const queryParams = { beginDate, endDate, type: this.ReaderType ? this.ReaderType : 0, bid: this.selectReaderLibId };
            await countLendRecordReader(queryParams).then(({ data }) => {
                this.eLegendData2 = data.inLibraryDateList;
                this.eseries2[0].data = data.activeReaderList;
                this.eseries2[1].data = data.inLibraryReaderList;
            })
            const options = {
                tooltip: {
                    trigger: 'axis'
                },
                title: {
                    text: '统计'
                },
                legend: {
                    data: ['活跃读者', '馆内读者',]
                },
                xAxis: {
                    data: this.exAxis2
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {},
                series: [
                    {
                        name: '活跃读者',
                        type: 'line',
                        data: this.eseries2[0].data,
                        type: 'line',
                        stack: 'x'
                    },
                    {
                        name: '馆内读者',
                        type: 'line',
                        data: this.eseries2[1].data,
                        type: 'line',
                        stack: 'x'
                    }
                ]
            }
            this.myChart2.setOption(options)
        },

        //================== 读者画像================
        getYesterDay() {
            const previousDate = new Date();
            previousDate.setDate(previousDate.getDate() - 1);
            const year = previousDate.getFullYear();
            const month = String(previousDate.getMonth() + 1).padStart(2, '0');
            const day = String(previousDate.getDate()).padStart(2, '0');
            this.YesDate = `${year}-${month}-${day}`;
        },
        showReaderFigureRegion(data) { this.selectReaderFigureLibId = data ? data : 1; },
        async ReaderFigureSearch() {
            let bid = this.selectReaderFigureLibId
            await readerFigure({ bid }).then(({ data }) => {
                console.log(data);
                this.eseries3[0].value = data.manNum;
                this.eseries3[1].value = data.womanNum;
                this.eseries3[2].value = data.otherNum;
                this.exAxis3 = data.ageList;
            })
            const options = {
                title: {
                    text: '性别',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '40%',
                        data: [
                            { value: this.eseries3[0].value, name: '男' },
                            { value: this.eseries3[1].value, name: '女' },
                            { value: this.eseries3[2].value, name: '其他' },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
            this.myChart3.setOption(options);
            const optionsFour = {
                title: {
                    text: '年龄',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '40%',
                        data: [
                            { value: this.exAxis3[0], name: '0-3' },
                            { value: this.exAxis3[1], name: '4-6' },
                            { value: this.exAxis3[2], name: '7-9' },
                            { value: this.exAxis3[3], name: '10-12' },
                            { value: this.exAxis3[4], name: '13-15' },
                            { value: this.exAxis3[5], name: '16-18' },
                            { value: this.exAxis3[6], name: '18以上' },
                            { value: this.exAxis3[7], name: '为空' },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
            this.myChart4.setOption(optionsFour);
        },


        // =================读者排行榜================
        showReaderRankrelativeDayList(value) { this.getYesDate(value); },
        showReaderRanklibRegion(data) { this.selectReaderRankLibId = data ? data : 1; },
        async ReaderRankSearch() {
            let beginDate, endDate;
            if (this.queryReaderRankForm.value0 != 0 && this.queryReaderRankForm.value1 != 0) {
                beginDate = this.queryReaderRankForm.value0;
                endDate = this.queryReaderRankForm.value1;
            }
            else {
                beginDate = this.dateArray[(this.dateArray.length) - 1]
                endDate = this.dateArray[0]
            }
            const queryReaderRankParams = { beginDate, endDate, bid: this.selectReaderRankLibId };
            await countLendRecordReaderRank(queryReaderRankParams).then(({ data }) => {
                this.ReaderRankList = data;
                this.ReaderRankList = this.ReaderRankList.sort((a, b) => b.lendNum - a.lendNum)
                this.ReaderRankList = this.ReaderRankList.map((item, index) => {
                    return { ...item, order: index + 1 }
                })
                console.log(this.ReaderRankList);
            })
        },

        // ================= 图书馆数据 ===========
        // 点击截止时间之后，脱离焦点
        getData(date = this.delineDateBak, val = 5) {
            // console.log(this.delineDate);
            // this.delineDateBak = formatDate(this.delineDate)
            // console.log(this.delineDateBak);
            libraryData({ date: date }).then(({ data }) => {
                this.LabData = data
                // 分页
                this.LabData = this.LabData.slice(0, val);
            })

        },


        // =============== 全局方法 =================
        // 获得分馆列表
        getLibList() {
            const queryParams = { ...this.queryForm, page: this.page.page, size: this.page.size }
            listLibrary(queryParams).then(({ data }) => {
                // console.log(data);
                this.libRegion = data.libraryBranchData;
                this.page.total = data.total;
                this.libRegion.unshift(this.libAll)
            })
        },
        handleClick(tab, event) {
            // 根据不同选项，组件显示筛选0概览 1借阅数据 2图书数据 3读者数据 4图书馆数据
            this.active = tab.index;
            if (this.active == '0') {

            }
            if (this.active == "1") {
            }
            if (this.active == "2") {
            }
            if (this.active == "3") {
            }
            if (this.active == "4") {
            }
        },
        formatter(row, column) {
            return row.address;
        },
        handleSizeChange(val) {
            this.pageSize = val;
            console.log(this.pageSize);
            console.log(this.LabData.length);
            this.getData(this.delineDateBak, val)
        },
        // 获得昨天时间,前几天时间
        getYesDate(x = 7) {
            const currentDate = new Date();
            for (let i = 0; i < x; i++) {
                const previousDate = new Date(currentDate);
                previousDate.setDate(previousDate.getDate() - i);
                // 格式化日期
                const year = previousDate.getFullYear();
                const month = String(previousDate.getMonth() + 1).padStart(2, '0');
                const day = String(previousDate.getDate()).padStart(2, '0');
                this.dateArray.push(`${year}-${month}-${day}`)
            }
        },
    }
}
</script>

<style scoped>
.search {
    display: flex;
}


.LibStatus {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.line {
    border-right: 1px solid gainsboro;
    width: 2px;
    margin: 0;
}

.number {
    font-size: 50px;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    min-width: 140px;
}

.txt {
    font-size: 16px;
    color: #858585;
    display: flex;
    justify-content: center;
    min-width: 140px;
}

.TodayData {
    padding: 15px;
    background: #FFF;
    border: 1px solid #DCDFE6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    margin-top: 10px;
    justify-content: space-between;
}

.TodayData .LibStatus1 {
    display: flex;
    justify-content: space-between;
}

.OtherData {
    width: 360px;
    padding: 15px;
    background: #FFF;
    border: 1px solid #DCDFE6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    margin-top: 10px;
}


.second-item {
    display: flex;
}

.second-right {
    position: absolute;
    z-index: 10;
    top: 100px;
    right: 0;
    margin-left: 20px;
    padding: 10px;
    background: #ba6b6b;
    min-width: 220px;
    min-height: 204px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFF;
    border: 1px solid #DCDFE6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
}

.number {
    font-size: 50px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
}

.txt {
    margin-top: -20px;
    font-size: 16px;
    color: #858585;
    display: flex;
    justify-content: center;
    min-width: 140px;
}

#main1 {
    width: 680px;
    height: 450px;
}

#main2 {
    width: 680px;
    height: 450px;
}

#main3 {
    width: 300px;
    height: 300px;
}

#main4 {
    width: 330px;
    height: 300px;
}

/* 饼图 */
.circle {
    display: flex;
    position: absolute;
    z-index: 999;
    bottom: -40px;
    left: 50px;
}


.bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.bottom .left {
    padding: 15px;
    background: #FFF;
    border: 1px solid #DCDFE6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    margin-top: 10px;
    width: 300px;
}

.bottom .right {
    padding: 15px;
    background: #FFF;
    border: 1px solid #DCDFE6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    margin-top: 10px;
    min-width: 650px;
}

.five {}

.five .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.five .top .right {
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    color: #515a6e;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
}
</style>